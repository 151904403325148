import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BreadcrumbService } from '../../breadcrumb.service';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Properties } from '../general/properties';
import { DefaultService, RulesService } from '../../_services';
import { Constantes } from '../../_shared/var.constant';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';

@Component({
  selector: 'app-tramites',
  templateUrl: './tramites.component.html',
  styleUrls: ['./tramites.component.css']
})

export class TramitesComponent implements OnInit {

  properties = new Properties();
  pais: string = 'EC';
  constantes = new Constantes();
  es: any = this.properties.es;
  rowsPerPage = this.properties.tableProperties.rows;
  // Tabs principales
  estadoTramites: SelectItem[] = [];
  estadoTramiteSeleccionado: any;
  firstValue = 0;
  finalValue = 0;
  // Buscador
  globalFilter: any;
  tipoFecha: SelectItem[] = [];
  tipoFechaSeleccionada: any;
  cols: any[];
  ramos: SelectItem[] = [
    { label: 'Todos', value: null }
  ];
  tramites: any[] = [];
  estadosFiltro: any[];
  tramitesLista: any[];
  documents: any[];
  totalPages = 0;
  documentsBack: any[];
  firstPanel = true;
  totalRecords = 0;
  tableFields = "tableFields"
  dateType = 'CREATION_DATE';
  startDate = '';
  endDate = '';
  bandera;
  selected: any;
  filtrosTabla = {
    'ramo': null,
    'tramites': null,
    'estado': null
  };
  todosAux = [];
  first = 0;

  constructor(private http: HttpClient,
    public policyService: DefaultService,
    public route: ActivatedRoute,
    public service: DefaultService,
    public rulesServices: RulesService,
    public documentsService: DocumentsService,
    private messageService: MessageService,
    private catalogService: CatalogService,
    private breadcrumbService: BreadcrumbService,
  ) {
  }


  ngOnInit() {
    this.cargarCatalogos();
    this.countDocuments();
    this.armarTabla();
    this.buscar();
    this.estadoTramiteSeleccionado = {
      "label": "Para tu gestión",
      "title": "Para tu gestión",
      "value": 0
    }
    this.cargarBreadCrum();

  }

  cargarBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
      { label: 'Gestión de solicitudes' },
      { label: 'Mis documentos', routerLink: ['/tramites'] }
    ]);
  }

  buscador() {

  }

  updatePag(event) {
    this.first = event.first;
    const slicedArray = this.documentsBack.slice(event.first, event.first + this.rowsPerPage);
    if (slicedArray.length < this.rowsPerPage) {
      if (Math.ceil((event.first + this.rowsPerPage) / this.rowsPerPage) != this.totalPages || slicedArray.length == 0) {
        this.buscar(Math.ceil((event.first + this.rowsPerPage) / this.rowsPerPage), null, false);
      }
    }
    this.documents = slicedArray;
  }

  changeTab(event) {
    this.filtrosTabla = {
      'ramo': null,
      'tramites': null,
      'estado': null
    };
    this.documents = null;
    this.dateType = 'CREATION_DATE';
    if (this.estadoTramiteSeleccionado.label == "Para tu gestión") {
      this.estadosFiltro = this.properties.estadosPolizasFiltro;
    }
    if (this.estadoTramiteSeleccionado.label != "Para tu gestión") {
      this.estadosFiltro = this.properties.estadosTramitesEndFiltro;
    }

    switch (event.value.label) {
      case "Para tu gestión":
        this.cols = this.cols.filter(curr => curr.header != 'Fecha vigencia' || curr.field != 'endEffectiveDate');
        this.firstPanel = true;
        break;
      case "Finalizados":
        this.cols.push({
          field: "endEffectiveDate", header: 'Fecha vigencia'
        });
        this.firstPanel = false;
        break
      default:
        break;
    }
    this.buscar(1, true);
  }

  async countDocuments() {
    this.documentsService.getCountDocuments('EC', true, {
      startPanel: true
    }).then(
      result => {
        this.firstValue = result;
      }
    );
    this.documentsService.getCountDocuments('EC', true, {
      startPanel: false
    }).then(
      result => {
        this.finalValue = result;
      }
    );
  }

  async buscar(page = 1, restart = false, cPage = true) {
    const params = {
      startPanel: this.firstPanel,
      globalFilter: this.globalFilter,
      dateType: this.dateType,
      startDate: this.startDate,
      endDate: this.endDate,
      formType: this.filtrosTabla['tramites'],
      branch: this.filtrosTabla['ramo'],
      status: this.filtrosTabla['estado'],
    };
    if (cPage) {
      this.documentsService.getCountDocuments('EC', 'pages', params).then(
        result => {
          this.totalRecords = result;
          this.totalPages = Math.ceil(result / this.rowsPerPage);
        }
      ); 
    }
    if (restart) {
      this.first = 1;
    }
    if ((this.startDate && this.endDate) &&
      (this.startDate > this.endDate)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'La fecha inicial no puede ser mayor a la fecha final' });
    } else {
      const resp = await this.documentsService.getDocuments({
        ...params,
        limit: this.rowsPerPage,
        page
      });
        if (resp.errorType == 'Function.ResponseSizeTooLarge') {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Existen muchos documentos entre el rango establecido, intente colocando un menor rango.' });
          return
        }
        this.armarCamposTabla(resp);
        if (page != 1) {
          const slicedArray = resp.slice((page - 1) * this.rowsPerPage, (page * this.rowsPerPage));
          this.documents = slicedArray;
        } else {
          this.documents = resp.slice(0, this.rowsPerPage);
        }
        this.documentsBack = resp;
        if (this.documents != null) {
          this.todosAux = [...this.documents];
        }
    }
  }

  armarTabla() {
    this.cols = [
      { field: 'requestType', header: 'Tipo de Solicitud' },
      { field: 'creationDate', header: 'Fecha de Creación' },
      { field: 'contractor', header: ' Contratante' },
      { field: 'documentNumber', header: ' N° de Documento' },
      { field: 'dateId', header: ' N° de Solicitud' },
      { field: 'branchName', subfield: 'descripcion', header: 'Ramo', id: 'ramo' },
      { field: "status", header: 'Estado', id: 'estado' },
      // { field: null, header: 'Acción' }
    ];
  }

  cargarCatalogos() {
    this.tramites.push({ label: 'Todos', value: null }, { label: 'Póliza', value: 'POLICY' }, { label: 'Formulario', value: 'FORM' });
    this.estadoTramites.push({ label: 'Para tu gestión', title: 'Para tu gestión', value: 0 }, { label: 'Finalizados', title: 'Finalizados', value: 1 });
    this.tipoFecha.push({ label: 'Fecha de Creación ', value: 'CREATION_DATE' }, { label: 'Fecha de Emisión', value: 'EMISSION_DATE' }, { label: 'Fecha de Vigencia ', value: 'EFFECTIVE_DATE' });
    this.estadosFiltro = this.properties.estadosPolizasFiltro;
    this.catalogService.getCatalogs(this.properties.ramos, this.pais)
      .subscribe((resp: any) => {
        if (resp) {
          resp.forEach(element => {
            this.ramos.push({ label: element.description, value: element.id });
          });
        }
      },
        (err: any) => console.log(err),
        () => {
        });
  }

  filtrar(event, tipo) {
    if (event.value) {
      let filtro = []
      switch (tipo) {
        case 0:
          filtro = this.todosAux.filter(x => x.requestType == event.value)
          this.documents = filtro
          break;
        case 1:
          filtro = this.todosAux.filter(x => x.branchId == event.value)
          this.documents = filtro
          break;
        case 2:
          filtro = this.todosAux.filter(x => x.status == event.value)
          this.documents = filtro
          break;

        default:
          break;
      }
    } else {
      this.documents = this.todosAux
    }

  }
  borrarFiltros() {
    this.globalFilter = null;
    this.dateType = 'CREATION_DATE';
    this.startDate = null;
    this.endDate = null;
    this.filtrosTabla = {
      'ramo': null,
      'tramites': null,
      'estado': null
    }
    this.buscar(1, true);
  }

  gestionar(rowData) {
    this.selected = rowData;
    this.bandera = rowData.requestType
  }
  volver(event) {
    this.bandera = null;
  }
  armarCamposTabla(tabla) {
    if (tabla)
      tabla.forEach(x => {
        x[this.tableFields] = {}
        x[this.tableFields]["creationDate"] = x.creationDate
        x[this.tableFields]["status"] = this.properties.traduccion[x.status]
        if (x.locked && x.status == this.properties.estadosDocumentos.PENDING) {
          x[this.tableFields]["status"] = this.properties.traduccion.LOCKED
        }
        x[this.tableFields]["requestType"] = this.properties.traduccion[x.requestType]
        x[this.tableFields]["contractor"] = x.contractor;
        x[this.tableFields]["documentNumber"] = x.documentNumber;
        x[this.tableFields]["endEffectiveDate"] = x.endEffectiveDate;
        if (x && x.branch) {
          x[this.tableFields]["branchName"] = x.branch;
        }
        x[this.tableFields]["dateId"] = x.dateId
      });
    /*
    fecha creacion = creationDate
    contrante= data.persona 2 nombre 2 apellidos
    numerodocumento= data.persona.documentNumber
    dateId
    ramo EntityBranchModel.description
    status;*/

  }
}

